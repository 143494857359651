import loadable from '@loadable/component';

import SmartRender from '@Components/SmartRender';
import Root from '@Pages/Root';
import { loader as rootLoader } from '@Pages/Root/loader';
import { loader as pageIndexLoader } from '@Pages/PageIndex/loader';
import { loader as pageCatalogLoader } from '@Pages/PageCatalog/loader';
import { loader as pageProductLoader } from '@Pages/PageProduct/loader';
import { loader as pageCategoryLoader } from '@Pages/PageCategory/loader';
import { loader as pageSiteMapLoader } from '@Pages/PageSiteMap/loader';
import { loader as pageAboutLoader } from '@Pages/PageAbout/loader';
import { loader as pageProductionLoader } from '@Pages/PageProduction/loader';
import { loader as pageMaterialsLoader } from '@Pages/PageMaterials/loader';
import { loader as pagePresslsLoader } from '@Pages/PagePress/loader';
import { loader as pageWarrantyLoader } from '@Pages/PageWarranty/loader';
import { loader as pageWikiArticleLoader } from '@Pages/PageWikiArticle/loader';
import { loader as pageWikiRubricLoader } from '@Pages/PageWikiRubric/loader';
import { loader as pageWikiTagLoader } from '@Pages/PageWikiTag/loader';
import { loader as pageWikiMainLoader } from '@Pages/PageWikiMain/loader';
import { loader as pageB2BLoader } from '@Pages/PageB2b/loader';
import { loader as pageB2bHorecaLoader } from '@Pages/PageB2bHoreca/loader';
import { loader as pageB2bDetailLoader } from '@Pages/PageB2bHoreca/PageB2bDetail/loader';
import { loader as pageB2bRegistratsiyuLoader } from '@Pages/PageB2bRegistratsiyu/loader';
import { loader as pageTextileSamplesLoader } from '@Pages/PageTextileSamples/loader';
import { loader as pageSmartFabricsLoader } from '@Pages/PageSmartFabrics/loader';
import { loader as pageShowroomLoader } from '@Pages/PageShowroom/loader';
import { loader as pageTrendsLoader } from '@Pages/PageTrends/loader';
import { loader as pagePageContactsLoader } from '@Pages/PageContacts/loader';
import { loader as pagePageCookiesPolicyLoader } from '@Pages/PageCookiesPolicy/loader';
import { loader as pagePageCreditLoader } from '@Pages/PageCredit/loader';
import { loader as pagePageDeliveryLoader } from '@Pages/PageDelivery/loader';
import { loader as pagePagePaymentLoader } from '@Pages/PagePayment/loader';
import { loader as pagePagePaymentSecurityLoader } from '@Pages/PagePaymentSecurity/loader';
import { loader as pageCompareLoader } from '@Pages/PageCompare/loader';
import { loader as pageOrderStatusLoader } from '@Pages/PageOrderStatus/loader';
import { loader as pageOrderCheckLoader } from '@Pages/PageOrderCheck/loader';
import { loader as pageOrderCheckSuccessLoader } from '@Pages/PageOrderCheckSuccess/loader';
import { loader as pageAboutConstructorLoader } from '@Pages/PageAboutConstructor/loader';
import { loader as pageConstructorLoader } from '@Pages/PageConstructor/loader';
import { loader as pageCareersLoader } from '@Pages/PageCareers/loader';
import { loader as pageDlyaDetskoy } from '@Pages/PageDlyaDetskoy/loader';
import { loader as pageSearchLoader } from '@Pages/PageSearchCommon/loader';
import { loader as pageGeographyLoader } from '@Pages/PageGeography/loader';
import { loader as pageQualityDepartmentLoader } from '@Pages/PageQualityDepartment/loader';
import { loader as pagePriceTagGeneratorLoader } from '@Pages/PagePriceTagGenerator/loader';
import { loader as pageStaticLoader } from '@Pages/PageStatic/loader';
import { loader as pagePrivacyPolicyLoader } from '@Pages/PagePrivacyPolicy/loader';
import { loader as pageTytryadomLoader } from '@Pages/PageTytryadom/loader';
import { loader as pageSleeperLoader } from '@Pages/PageSleeper/loader';
import { loader as pageuizMattrassesLoader } from '@Pages/PageQuizMattrasses/loader';
import { loader as pageQuizClosetLoader } from '@Pages/PageQuizCloset/loader';
import { loader as pageQuizzLoader } from '@Pages/PageQuizz/loader';
import { loader as pageElaxLoader } from '@Pages/PageElax/loader';
import { loader as pagePromoKitsLoader } from '@Pages/PagePromoKits/loader';
import { loader as pagePromoPrintsLoader } from '@Pages/PagePromoPrints/loader';
import { loader as pagePromotionsDiscountsLoader } from '@Pages/PagePromotionsDiscounts/loader';
import { loader as pageBalanceHistoryLoader } from '@Pages/PageBalanceHistory/loader';
import { loader as pageMyOrdersLoader } from '@Pages/PageMyOrders/loader';
import { loader as pageMyComplaintsLoader } from '@Pages/PageMyComplaints/loader';
import { loader as pageDivanClubLoader } from '@Pages/PageDivanClub/loader';
import { loader as pageCabinetLoader } from '@Pages/PageCabinet/loader';
import { loader as pageRibbleLoader } from '@Pages/PageRibble/loader';
import { loader as pageSchoolFurnitureLoader } from '@Pages/PageSchoolFurniture/loader';
import { loader as pageRabocheeMestoLoader } from '@Pages/PageRabocheeMesto/loader';
import { loader as pageDesignAwardsLoader } from '@Pages/PageDesignAwards/loader';
import { loader as pageFederalAdvertisingCompanyLoader } from '@Pages/FederalAdvertisingCompany/loader';
import { loader as pageMimaLoader } from '@Pages/PageMima/loader';
import { loader as pageTinkoffLoader } from '@Pages/PageTinkoff/loader';
import { loader as pageFavoritesLoader } from '@Pages/PageFavorites/loader';
import { loader as pageYandexSplitLoader } from '@Pages/PageYandexSplit/loader';
import { loader as pageNewYearCollection } from '@Pages/PageNewYearCollection/loader';
import { loader as pageExpressDesign } from '@Pages/PageExpressDesign/loader';
import { loader as pageVozvyshayaComfort } from '@Pages/PageVozvyshayaComfort/loader';
import { loader as pageFacturnayaPoloska } from '@Pages/PageFacturnayaPoloska/loader';
import { loader as pageFlowermarket } from '@Pages/PageFlowermarket/loader';
import { loader as pageHranenie } from '@Pages/PageHranenie/loader';
import { loader as pageSvadba } from '@Pages/PageSvadba/loader';
import { loader as pageOutdoor } from '@Pages/PageOutdoor/loader';
import { loader as pageNeochevidnyeInstrukcii } from '@Pages/PageNeochevidnyeInstrukcii/loader';
import { loader as pageVmesteDeshevleLoader } from '@Pages/PageVmesteDeshevle/loader';
import { loader as pageDeliveryTracking } from '@Pages/PageDeliveryTracking';
import { loader as pageShowroomVisit } from '@Pages/PageShowroomVisit/loader';
import { loader as pageCabinetVisitsLoader } from '@Pages/PageCabinetVisits/loader';
import { loader as pageCabinetVisitLoader } from '@Pages/PageCabinetVisit/loader';
import { loader as pagePickAndPair } from '@Pages/PagePickAndPair/loader';
import { lazy as pageB2bHorecaLazy } from '@Pages/PageB2bHoreca/lazy';
import { lazy as pageSearchCommonLazy } from '@Pages/PageSearchCommon/lazy';

import type { RouteObject } from 'react-router-dom';
import type { Query } from '@Types/Base';
import type { RouteRule, LoaderFnArgs } from '@Types/Router';

const PageIndex = loadable(() => import('@Pages/PageIndex'));
const PageCategory = loadable(() => import('@Pages/PageCategory'));
const PageProduct = loadable(() => import('@Pages/PageProduct'));
const PagePayment = loadable(() => import('@Pages/PagePayment'));
const PageCredit = loadable(() => import('@Pages/PageCredit'));
const PageWarranty = loadable(() => import('@Pages/PageWarranty'));
const PageB2b = loadable(() => import('@Pages/PageB2b'));
const PageB2bHoreca = loadable(() => import('@Pages/PageB2bHoreca'));
const PageB2bDetail = loadable(() => import('@Pages/PageB2bHoreca/PageB2bDetail'));
const PageB2bRegistratsiyu = loadable(() => import('@Pages/PageB2bRegistratsiyu'));
const PageDelivery = loadable(() => import('@Pages/PageDelivery'));
const PageQualityDepartment = loadable(() => import('@Pages/PageQualityDepartment'));
const PageContacts = loadable(() => import('@Pages/PageContacts'));
const PageCareers = loadable(() => import('@Pages/PageCareers'));
const PagePrivacyPolicy = loadable(() => import('@Pages/PagePrivacyPolicy'));
const PageCookiesPolicy = loadable(() => import('@Pages/PageCookiesPolicy'));
const PagePaymentSecurity = loadable(() => import('@Pages/PagePaymentSecurity'));
const OrderSection = loadable(() => import('@Navigation/OrderSection'));
const PageOrderStatus = loadable(() => import('@Pages/PageOrderStatus'));
const PageOrderCheck = loadable(() => import('@Pages/PageOrderCheck'));
const PageOrderCheckSuccess = loadable(() => import('@Pages/PageOrderCheckSuccess'));
const PageTextileSamples = loadable(() => import('@Pages/PageTextileSamples'));
const PagePress = loadable(() => import('@Pages/PagePress'));
const PageSiteMap = loadable(() => import('@Pages/PageSiteMap'));
const PageShowroom = loadable(() => import('@Pages/PageShowroom'));
const PagePromotionsDiscounts = loadable(() => import('@Pages/PagePromotionsDiscounts'));
const PagePromoKits = loadable(() => import('@Pages/PagePromoKits'));
const PagePromoPrints = loadable(() => import('@Pages/PagePromoPrints'));
const PageAboutConstructor = loadable(() => import('@Pages/PageAboutConstructor'));
const PageSleeper = loadable(() => import('@Pages/PageSleeper'));
const PageCompare = loadable(() => import('@Pages/PageCompare'));
const PageQuizMattrasses = loadable(() => import('@Pages/PageQuizMattrasses'));
const PageQuizCloset = loadable(() => import('@Pages/PageQuizCloset'));
const PageQuestionnaire = loadable(() => import('@Pages/PageQuestionnaire'));
const PageQuizz = loadable(() => import('@Pages/PageQuizz'));
const Page404 = loadable(() => import('@Pages/Page404'));
const PageSearchCommon = loadable(() => import('@Pages/PageSearchCommon'));
const PrivateSection = loadable(() => import('@Navigation/PrivateSection'));
const PageCabinet = loadable(() => import('@Pages/PageCabinet'));
const PageMyOrders = loadable(() => import('@Pages/PageMyOrders'));
const PageMyComplaints = loadable(() => import('@Pages/PageMyComplaints'));
const PageBalanceHistory = loadable(() => import('@Pages/PageBalanceHistory'));
const PageConstructor = loadable(() => import('@Pages/PageConstructor'));
const PageWikiArticle = loadable(() => import('@Pages/PageWikiArticle'));
const PageWikiMain = loadable(() => import('@Pages/PageWikiMain'));
const PageWikiRubric = loadable(() => import('@Pages/PageWikiRubric'));
const PageWikiTag = loadable(() => import('@Pages/PageWikiTag'));
const PageElax = loadable(() => import('@Pages/PageElax'));
const PageAbout = loadable(() => import('@Pages/PageAbout'));
const PageMaterials = loadable(() => import('@Pages/PageMaterials'));
const PageProduction = loadable(() => import('@Pages/PageProduction'));
const PageDivanClub = loadable(() => import('@Pages/PageDivanClub'));
const PageGeography = loadable(() => import('@Pages/PageGeography'));
const PageTrends = loadable(() => import('@Pages/PageTrends'));
const PageStatic = loadable(() => import('@Pages/PageStatic'));
const PageSmartFabrics = loadable(() => import('@Pages/PageSmartFabrics'));
const PageCatalog = loadable(() => import('@Pages/PageCatalog'));
const PagePriceTagGenerator = loadable(() => import('@Pages/PagePriceTagGenerator'));
const PageTytryadom = loadable(() => import('@Pages/PageTytryadom'));
const PageRibble = loadable(() => import('@Pages/PageRibble'));
const PageSchoolFurniture = loadable(() => import('@Pages/PageSchoolFurniture'));
const PageRabocheeMesto = loadable(() => import('@Pages/PageRabocheeMesto'));
const PageDlyaDetskoy = loadable(() => import('@Pages/PageDlyaDetskoy'));
const PageHranenie = loadable(() => import('@Pages/PageHranenie'));
const PageSvadba = loadable(() => import('@Pages/PageSvadba'));
const PageDesignAwards = loadable(() => import('@Pages/PageDesignAwards'));
const PageFederalAdvertisingCompany = loadable(() => import('@Pages/FederalAdvertisingCompany'));
const PageError = loadable(() => import('@Pages/PageError'));
const PageMima = loadable(() => import('@Pages/PageMima'));
const PageTinkoff = loadable(() => import('@Pages/PageTinkoff'));
const PageFavorites = loadable(() => import('@Pages/PageFavorites'));
const PageYandexSplit = loadable(() => import('@Pages/PageYandexSplit'));
const PageNewYearCollection = loadable(() => import('@Pages/PageNewYearCollection'));
const PageExpressDesign = loadable(() => import('@Pages/PageExpressDesign'));
const PageVozvyshayaComfort = loadable(() => import('@Pages/PageVozvyshayaComfort'));
const PageFacturnayaPoloska = loadable(() => import('@Pages/PageFacturnayaPoloska'));
const PageOutdoor = loadable(() => import('@Pages/PageOutdoor'));
const PageFlowermarket = loadable(() => import('@Pages/PageFlowermarket'));
const PageNeochevidnyeInstrukcii = loadable(() => import('@Pages/PageNeochevidnyeInstrukcii'));
const PageVmesteDeshevle = loadable(() => import('@Pages/PageVmesteDeshevle'));
const PageDeliveryTracking = loadable(() => import('@Pages/PageDeliveryTracking'));
const PageShowroomVisit = loadable(() => import('@Pages/PageShowroomVisit'));
const PageCabinetVisits = loadable(() => import('@Pages/PageCabinetVisits'));
const PageCabinetVisit = loadable(() => import('@Pages/PageCabinetVisit'));
const PagePickAndPair = loadable(() => import('@Pages/PagePickAndPair'));

const slugParam = `:slug`;
const pageParam = `:page`;
const regionParam = `:region`;
const langParam = `:lang`;

// Адреса старых лендингов, перенаправляемые на '/landing-club/':
// - /landing/cntx1000rewards
// - /landing/online-bot-registration
// - /landing/flry2000l3nj9
// - /landing/vk
// - /landing/tg
// - /landing/ig
// - /landing/yt
// - /landing/equ2000hdk

const mainRoutes: RouteRule[] = [
  {
    path: `product/${slugParam}`,
    element: <PageProduct />,
    loader: pageProductLoader,
    lazy: async () => {
      const { default: Component } = await import('@Pages/PageProduct');

      return { Component };
    },
  },
  {
    path: `product-preview/${slugParam}`,
    element: <PageProduct />,
    loader: pageProductLoader,
    lazy: async () => {
      const { default: Component } = await import('@Pages/PageProduct');

      return { Component };
    },
  },
  {
    path: 'akcii-skidki',
    element: <PagePromotionsDiscounts />,
    loader: pagePromotionsDiscountsLoader,
    lazy: async () => {
      const { default: Component } = await import('@Pages/PagePromotionsDiscounts');

      return { Component };
    },
  },
  {
    path: 'sleeper',
    index: true,
    element: <PageSleeper />,
    loader: pageSleeperLoader,
    lazy: async () => {
      const { default: Component } = await import('@Pages/PageSleeper');

      return { Component };
    },
  },
  {
    path: 'favorites',
    element: <PageFavorites />,
    loader: pageFavoritesLoader,
    lazy: async () => {
      const { default: Component } = await import('@Pages/PageFavorites');

      return { Component };
    },
    children: [
      {
        path: pageParam,
        element: <PageFavorites />,
        loader: pageFavoritesLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageFavorites');

          return { Component };
        },
      },
    ],
  },
  {
    path: 'compare',
    element: <PageCompare />,
    loader: pageCompareLoader,
    lazy: async () => {
      const { default: Component } = await import('@Pages/PageCompare');

      return { Component };
    },
  },
  {
    path: 'sofa-constructor',
    element: <PageConstructor slug='sofa-constructor' />,
    loader: pageConstructorLoader('sofa-constructor'),
    lazy: async () => {
      const { default: Component } = await import('@Pages/PageConstructor');

      return { Component: () => <Component slug='sofa-constructor' /> };
    },
    children: [
      {
        path: pageParam,
        element: <PageConstructor slug='sofa-constructor' />,
        loader: pageConstructorLoader('sofa-constructor'),
      },
    ],
  },
  {
    path: 'express-design',
    element: <PageExpressDesign />,
    loader: pageExpressDesign,
    lazy: async () => {
      const { default: Component } = await import('@Pages/PageExpressDesign');

      return { Component };
    },
  },
  {
    path: `commercial/${pageParam}`,
    element: <PageShowroomVisit />,
    loader: pageShowroomVisit,
    lazy: async () => {
      const { default: Component } = await import('@Pages/PageShowroomVisit');
      return { Component };
    },
  },

  // Category
  {
    path: 'category',
    children: [
      {
        path: 'promo-vmeste-deshevle',
        element: (
          <SmartRender countries={['RUS']}>
            <PageVmesteDeshevle />
          </SmartRender>
        ),
        loader: pageVmesteDeshevleLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageVmesteDeshevle');

          return { Component };
        },
      },
      {
        path: 'poigraem-v-ribbl',
        element: <PageRibble />,
        loader: pageRibbleLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageRibble');

          return { Component };
        },
      },
      {
        path: 'promo-sozdai-sam',
        element: <PageConstructor slug='promo-sozdai-sam' />,
        loader: pageConstructorLoader('promo-sozdai-sam'),
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageConstructor');

          return { Component: () => <Component slug='promo-sozdai-sam' /> };
        },
        children: [
          {
            path: pageParam,
            element: <PageConstructor slug='promo-sozdai-sam' />,
            loader: pageConstructorLoader('promo-sozdai-sam'),
          },
        ],
      },
      {
        path: 'promo-bud-v-trende',
        element: <PageTrends />,
        loader: pageTrendsLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageTrends');

          return { Component };
        },
        children: [
          {
            path: pageParam,
            element: <PageTrends />,
            loader: pageTrendsLoader,
          },
        ],
      },
      {
        path: `promo-vmeste-vygodnee`,
        element: <PagePromoKits />,
        loader: pagePromoKitsLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PagePromoKits');

          return { Component };
        },
        children: [
          {
            path: pageParam,
            element: <PagePromoKits />,
            loader: pagePromoKitsLoader,
          },
        ],
      },
      {
        path: slugParam,
        element: <PageCategory />,
        loader: pageCategoryLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageCategory');

          return { Component };
        },
        children: [
          {
            path: pageParam,
            element: <PageCategory />,
            loader: pageCategoryLoader,
            lazy: async () => {
              const { default: Component } = await import('@Pages/PageCategory');

              return { Component };
            },
          },
        ],
      },
      {
        path: 'svoe-naidetsya',
        element: <PageFederalAdvertisingCompany />,
        loader: pageFederalAdvertisingCompanyLoader,
      },
      {
        path: 'promo-yandex-split',
        element: (
          <SmartRender countries={['RUS']}>
            <PageYandexSplit />
          </SmartRender>
        ),
        loader: pageYandexSplitLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageYandexSplit');

          return { Component };
        },
      },
    ],
  },

  // Personal area
  {
    path: 'cabinet',
    element: <PrivateSection />,
    lazy: async () => {
      const { default: Component } = await import('@Navigation/PrivateSection');

      return { Component };
    },
    children: [
      {
        index: true,
        element: <PageCabinet />,
        loader: pageCabinetLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageCabinet');

          return { Component };
        },
      },
      {
        path: 'balance-history',
        element: <PageBalanceHistory />,
        loader: pageBalanceHistoryLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageBalanceHistory');

          return { Component };
        },
      },
      {
        path: 'my-complaints',
        element: <PageMyComplaints />,
        loader: pageMyComplaintsLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageMyComplaints');

          return { Component };
        },
      },
      {
        path: 'my-orders',
        element: <PageMyOrders />,
        loader: pageMyOrdersLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageMyOrders');

          return { Component };
        },
      },
      {
        path: 'visits',
        element: <PageCabinetVisits />,
        loader: pageCabinetVisitsLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageCabinetVisits');

          return { Component };
        },
      },
      {
        path: `visit/${slugParam}`,
        element: <PageCabinetVisit />,
        loader: pageCabinetVisitLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageCabinetVisit');

          return { Component };
        },
      },
    ],
  },

  // Order
  {
    path: 'order',
    element: <OrderSection />,
    lazy: async () => {
      const { default: Component } = await import('@Navigation/OrderSection');

      return { Component };
    },
    children: [
      {
        path: 'check',
        element: <PageOrderCheck />,
        loader: (params) => {
          return pageOrderCheckLoader(params);
        },
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageOrderCheck');

          return { Component };
        },
      },
      {
        path: 'check/success',
        element: (
          <SmartRender countries={['RUS', 'KAZ']}>
            <PageOrderCheckSuccess />
          </SmartRender>
        ),
        loader: (params) => {
          return pageOrderCheckSuccessLoader(params);
        },
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageOrderCheckSuccess');

          return { Component };
        },
      },
      {
        path: 'status/:orderId',
        element: <PageOrderStatus />,
        loader: pageOrderStatusLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageOrderStatus');

          return { Component };
        },
      },
    ],
  },

  // Site
  {
    path: 'site',
    children: [
      {
        path: 'payment',
        element: <PagePayment />,
        loader: pagePagePaymentLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PagePayment');

          return { Component };
        },
      },
      {
        path: 'credit',
        element: <PageCredit />,
        loader: pagePageCreditLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageCredit');

          return { Component };
        },
      },
      {
        path: 'warranty',
        element: <PageWarranty />,
        loader: pageWarrantyLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageWarranty');

          return { Component };
        },
      },
      {
        path: 'delivery',
        element: <PageDelivery />,
        loader: pagePageDeliveryLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageDelivery');

          return { Component };
        },
      },
      {
        path: 'quality-department',
        element: <PageQualityDepartment />,
        loader: pageQualityDepartmentLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageQualityDepartment');

          return { Component };
        },
      },
      {
        path: 'contacts',
        element: <PageContacts />,
        loader: pagePageContactsLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageContacts');

          return { Component };
        },
      },
      {
        path: 'careers',
        element: <PageCareers />,
        loader: pageCareersLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageCareers');

          return { Component };
        },
      },
      {
        path: 'press',
        element: <PagePress />,
        loader: pagePresslsLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PagePress');

          return { Component };
        },
      },
      {
        path: 'fabrics',
        element: <PageTextileSamples />,
        loader: pageTextileSamplesLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageTextileSamples');

          return { Component };
        },
      },
      {
        path: 'site-map',
        element: <PageSiteMap />,
        loader: pageSiteMapLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageSiteMap');

          return { Component };
        },
      },
      {
        path: 'site-map-full',
        element: <PageSiteMap />,
        loader: pageSiteMapLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageSiteMap');

          return { Component };
        },
      },
      {
        path: 'showroom',
        element: <PageShowroom />,
        loader: pageShowroomLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageShowroom');

          return { Component };
        },
      },
      {
        path: 'constructor',
        element: <PageAboutConstructor />,
        loader: pageAboutConstructorLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageAboutConstructor');

          return { Component };
        },
      },
      {
        path: 'about',
        element: <PageAbout />,
        loader: pageAboutLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageAbout');

          return { Component };
        },
      },
      {
        path: 'materials',
        element: <PageMaterials />,
        loader: pageMaterialsLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageMaterials');

          return { Component };
        },
      },
      {
        path: 'production',
        element: <PageProduction />,
        loader: pageProductionLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageProduction');

          return { Component };
        },
      },
      {
        path: 'geography',
        element: <PageGeography />,
        loader: pageGeographyLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageGeography');

          return { Component };
        },
      },
      {
        path: 'smart-fabrics',
        element: <PageSmartFabrics slug='chistetika' />,
        loader: pageSmartFabricsLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageSmartFabrics');

          return { Component: () => <Component slug='chistetika' /> };
        },
      },
      {
        path: 'price-list',
        element: <PagePriceTagGenerator />,
        loader: pagePriceTagGeneratorLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PagePriceTagGenerator');

          return { Component };
        },
      },
      {
        path: 'divan-club',
        element: <PageDivanClub />,
        loader: pageDivanClubLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageDivanClub');

          return { Component };
        },
      },
      {
        path: 'mebel-club',
        element: <PageDivanClub />,
        loader: pageDivanClubLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageDivanClub');

          return { Component };
        },
      },
    ],
  },

  // Landing pages
  {
    path: 'landing',
    children: [
      {
        path: 'igrat-ychitsa-mechtat',
        element: (
          <SmartRender countries={['RUS', 'KAZ']}>
            <PageSchoolFurniture />
          </SmartRender>
        ),
        loader: pageSchoolFurnitureLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageSchoolFurniture');

          return { Component };
        },
      },
      {
        path: 'design-awards',
        element: (
          <SmartRender countries={['RUS']}>
            <PageDesignAwards />
          </SmartRender>
        ),
        loader: pageDesignAwardsLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageDesignAwards');

          return { Component };
        },
      },
    ],
  },

  // Landing
  {
    path: 'landing',
    children: [
      {
        path: 'mima',
        element: (
          <SmartRender countries={['RUS']}>
            <PageMima />
          </SmartRender>
        ),
        loader: pageMimaLoader,
      },
      {
        path: 'tinkoff',
        element: (
          <SmartRender countries={['RUS']}>
            <PageTinkoff />
          </SmartRender>
        ),
        loader: pageTinkoffLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageTinkoff');

          return { Component };
        },
      },
      {
        path: 'svoboda-zimnei-prirody',
        element: (
          <SmartRender countries={['RUS', 'KAZ', 'BLR']}>
            <PageNewYearCollection />
          </SmartRender>
        ),
        loader: pageNewYearCollection,
      },
      {
        path: 'vozvyshaya-comfort',
        element: (
          <SmartRender countries={['RUS', 'BLR']}>
            <PageVozvyshayaComfort />
          </SmartRender>
        ),
        loader: pageVozvyshayaComfort,
      },
      {
        path: 'facturnaya-poloska',
        element: (
          <SmartRender countries={['RUS', 'KAZ']}>
            <PageFacturnayaPoloska />
          </SmartRender>
        ),
        loader: pageFacturnayaPoloska,
      },
      {
        path: 'dom-i-sad',
        element: (
          <SmartRender countries={['RUS', 'KAZ', 'UZB']}>
            <PageOutdoor />
          </SmartRender>
        ),
        loader: pageOutdoor,
      },
      {
        path: 'dlya-detskoy',
        element: (
          <SmartRender countries={['RUS', 'KAZ', 'UZB']}>
            <PageDlyaDetskoy />
          </SmartRender>
        ),
        loader: pageDlyaDetskoy,
      },
      {
        path: 'hranenie',
        element: (
          <SmartRender countries={['RUS']}>
            <PageHranenie />
          </SmartRender>
        ),
        loader: pageHranenie,
      },
      {
        path: 'suinshi',
        element: (
          <SmartRender countries={['KAZ', 'UZB']}>
            <PageSvadba />
          </SmartRender>
        ),
        loader: pageSvadba,
      },
      {
        path: 'rabochee-mesto',
        element: (
          <SmartRender countries={['RUS', 'KAZ', 'UZB']}>
            <PageRabocheeMesto />
          </SmartRender>
        ),
        loader: pageRabocheeMestoLoader,
      },
      {
        path: 'flowers',
        element: (
          <SmartRender countries={['RUS', 'KAZ', 'UZB', 'BLR']}>
            <PageFlowermarket />
          </SmartRender>
        ),
        loader: pageFlowermarket,
      },
      {
        path: 'neochevidnye-instrukcii',
        element: (
          <SmartRender countries={['RUS']}>
            <PageNeochevidnyeInstrukcii />
          </SmartRender>
        ),
        loader: pageNeochevidnyeInstrukcii,
      },
      {
        path: 'pick-and-pair',
        element: (
          <SmartRender countries={['RUS']}>
            <PagePickAndPair />
          </SmartRender>
        ),
        loader: pagePickAndPair,
      },
    ],
  },

  // Divan.Club && Mebel.Club
  {
    path: 'anton-tut-ryadom',
    element: (
      <SmartRender countries={['RUS']}>
        <PageTytryadom />
      </SmartRender>
    ),
    loader: pageTytryadomLoader,
    lazy: async () => {
      const { default: Component } = await import('@Pages/PageTytryadom');

      return { Component };
    },
  },

  {
    path: `landing-club/${slugParam}`,
    element: <PageDivanClub />,
    loader: pageDivanClubLoader,
    lazy: async () => {
      const { default: Component } = await import('@Pages/PageDivanClub');

      return { Component };
    },
  },

  // Search
  {
    path: 'search',
    element: (
      <PageSearchCommon digineticaCountries={['RUS', 'KAZ', 'UZB']} searchCountries={['BLR']} />
    ),
    loader: (params) => {
      return pageSearchLoader(params, {
        searchCountries: ['BLR'],
        digineticaCountries: ['RUS', 'KAZ', 'UZB'],
      });
    },
    lazy: async (query) => {
      const Component = await pageSearchCommonLazy(query, {
        searchCountries: ['BLR'],
        digineticaCountries: ['RUS', 'KAZ', 'UZB'],
      });

      return { Component };
    },
  },

  // Static pages
  {
    path: 'static-page',
    children: [
      {
        path: 'privacy-policy',
        element: <PagePrivacyPolicy />,
        loader: pagePrivacyPolicyLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PagePrivacyPolicy');

          return { Component };
        },
      },
      {
        path: 'payment-security',
        element: <PagePaymentSecurity />,
        loader: pagePagePaymentSecurityLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PagePaymentSecurity');

          return { Component };
        },
      },
      {
        path: 'politika-cookie',
        element: <PageCookiesPolicy />,
        loader: pagePageCookiesPolicyLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageCookiesPolicy');

          return { Component };
        },
      },
      {
        path: slugParam,
        element: <PageStatic />,
        loader: pageStaticLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageStatic');

          return { Component };
        },
      },
    ],
  },

  // B2B
  {
    path: 'b2b',
    children: [
      {
        index: true,
        element: <PageB2b />,
        loader: pageB2BLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageB2b');

          return { Component };
        },
      },
      {
        path: 'detail/resenia-dla-kafe-restoranov-i-gostinic',
        element: (
          <PageB2bHoreca landingCountries={['RUS']} detailCountries={['BLR', 'KAZ', 'UZB']} />
        ),
        loader: (params) => {
          return pageB2bHorecaLoader(params, {
            landingCountries: ['RUS'],
            detailCountries: ['BLR', 'KAZ', 'UZB'],
          });
        },
        lazy: async (query) => {
          const Component = await pageB2bHorecaLazy(query, {
            landingCountries: ['RUS'],
            detailCountries: ['BLR', 'KAZ', 'UZB'],
          });

          return { Component };
        },
      },
      {
        path: 'detail/dizajneram-i-arhitektoram',
        element: (
          <PageB2bHoreca landingCountries={['RUS', 'KAZ', 'UZB']} detailCountries={['BLR']} />
        ),
        loader: (params) => {
          return pageB2bHorecaLoader(params, {
            landingCountries: ['RUS', 'KAZ', 'UZB'],
            detailCountries: ['BLR'],
          });
        },
        lazy: async (query) => {
          const Component = await pageB2bHorecaLazy(query, {
            landingCountries: ['RUS', 'KAZ', 'UZB'],
            detailCountries: ['BLR'],
          });

          return { Component };
        },
      },
      {
        path: `detail/${slugParam}`,
        element: <PageB2bDetail />,
        loader: pageB2bDetailLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageB2bHoreca/PageB2bDetail');

          return { Component };
        },
      },
      {
        path: 'registratsiyu',
        element: <PageB2bRegistratsiyu />,
        loader: pageB2bRegistratsiyuLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageB2bRegistratsiyu');
          return { Component };
        },
      },
    ],
  },

  // Wiki
  {
    path: 'idei-i-trendy',
    children: [
      {
        path: `category/${slugParam}`,
        element: <PageWikiRubric />,
        loader: pageWikiRubricLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageWikiRubric');

          return { Component };
        },
      },
      {
        path: 'tag/:tagId',
        element: <PageWikiTag />,
        loader: pageWikiTagLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageWikiTag');

          return { Component };
        },
      },
      {
        path: slugParam,
        element: <PageWikiArticle />,
        loader: pageWikiArticleLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageWikiArticle');

          return { Component };
        },
      },
      {
        index: true,
        element: <PageWikiMain />,
        loader: pageWikiMainLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageWikiMain');

          return { Component };
        },
      },
    ],
  },

  // Promo pages
  {
    path: 'promo',
    children: [
      {
        path: 'prints',
        element: <PagePromoPrints slug='promo-prints' />,
        loader: pagePromoPrintsLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PagePromoPrints');

          return { Component: () => <Component slug='promo-prints' /> };
        },
      },
      {
        path: 'elax',
        element: <PageElax />,
        loader: pageElaxLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageElax');

          return { Component };
        },
        children: [
          {
            path: pageParam,
            element: <PageElax />,
            loader: pageElaxLoader,
            lazy: async () => {
              const { default: Component } = await import('@Pages/PageElax');

              return { Component };
            },
          },
        ],
      },
      {
        path: 'mattrasses',
        element: <PageQuizMattrasses />,
        loader: pageuizMattrassesLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageQuizMattrasses');

          return { Component };
        },
      },
      {
        path: 'quizz-shkafy/podborka',
        element: <PageQuizCloset />,
        loader: pageQuizClosetLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageQuizCloset');

          return { Component };
        },
      },
      {
        path: 'quizz-shkafy',
        element: <PageQuizz />,
        loader: pageQuizzLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageQuizz');

          return { Component };
        },
      },
      {
        path: `quizz-shkafy/${slugParam}`,
        element: <PageQuizz />,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageQuizz');

          return { Component };
        },
      },
    ],
  },

  // Catalog
  {
    path: 'catalog',
    children: [
      {
        path: 'room',
        element: <PageCatalog />,
        loader: pageCatalogLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageCatalog');

          return { Component };
        },
      },
      {
        path: 'product',
        element: <PageCatalog />,
        loader: pageCatalogLoader,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageCatalog');

          return { Component };
        },
      },
    ],
  },

  // Clients
  {
    path: 'clients',
    children: [
      {
        path: `questionnaire-manager/${slugParam}`,
        element: <PageQuestionnaire />,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageQuestionnaire');

          return { Component };
        },
      },
      {
        path: `questionnaire/${slugParam}`,
        element: <PageQuestionnaire />,
        lazy: async () => {
          const { default: Component } = await import('@Pages/PageQuestionnaire');

          return { Component };
        },
      },
    ],
  },

  // Delivery track
  {
    path: 'otsledit-zakaz',
    element: <PageDeliveryTracking />,
    loader: pageDeliveryTracking,
  },

  // Index
  {
    index: true,
    element: <PageIndex />,
    loader: pageIndexLoader,
    lazy: async () => {
      const { default: Component } = await import('@Pages/PageIndex');

      return { Component };
    },
  },
];

const routes: RouteRule[] = [
  {
    path: '/',
    element: <Root />,
    children: mainRoutes,
    loader: rootLoader,
    errorElement: <PageError />,
  },
  {
    path: `/${regionParam}`,
    element: <Root />,
    children: mainRoutes,
    loader: rootLoader,
    errorElement: <PageError />,
  },
  {
    path: `/${langParam}/${regionParam}`,
    element: <Root />,
    children: mainRoutes,
    loader: rootLoader,
    errorElement: <PageError />,
  },
  {
    path: '*',
    element: <Root />,
    loader: rootLoader,
    errorElement: <PageError />,
    children: [
      {
        element: <Page404 />,
      },
    ],
  },
];

export function createRoutes(query: Query): RouteObject[] {
  function wrapRoutes(inputRoutes: RouteRule[]) {
    return (inputRoutes || []).map((route) => {
      const lazy = typeof route.lazy === 'function' ? () => route.lazy(query) : undefined;
      return {
        ...route,
        loader: async (args: LoaderFnArgs) => {
          if (typeof route.loader === 'function') {
            const responses = await route.loader(query)(args);
            const response = Array.isArray(responses) ? responses.find(Boolean) : responses;

            return response || null;
          }

          return null;
        },
        children: route.children ? wrapRoutes(route.children) : undefined,
        lazy: typeof document === 'undefined' ? undefined : lazy,
      };
    });
  }

  const result = wrapRoutes(routes);

  return result;
}
